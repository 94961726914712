  <template>
    <div>
      <Navbar />
      <CouponBox/>
      <!-- <UnAvailable/> -->
      <HowToPlay/>
    </div>
  </template>

  <script>
  import Navbar from '@/components/navbar.vue'
  import CouponBox from '../components/coupon-box.vue'
  //import UnAvailable from '../components/unavailable.vue'
  import HowToPlay from '../components/how-to-play.vue'

  export default {
    name: 'App',
    components: {
      Navbar,
      CouponBox,
      HowToPlay,
     // UnAvailable
    }
  }
</script>

<style>

</style>