const platform = process.env.NODE_ENV;
let ApiUrl = 'https://localhost:7140';
switch (platform) {
    case "production":
        ApiUrl = 'https://kelloggs.musterlite.com';
        break;
    case "staging":
        ApiUrl = 'https://kelloggs.musterlite.com';
        break;
    default:
        break;
}
const config = {
    FetchVouchersUrl: `${ApiUrl}/promo/vouchers`,
    DisburseVoucherUrl: `${ApiUrl}/promo/disburse`,
    ValidateCouponCodeUrl: `${ApiUrl}/promo/validate/{code}`
}
    
console.log('platform',platform);
    
export default config;