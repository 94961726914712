<template>
    <div>
        <div class="modal fade" id="winnerModal" tabindex="-1">
            <div class="modal-dialog modal-fullscreen">
                <div class="modal-content">
                    <div class="modal-header border-0">
                        <button type="button" class="btn-close" data-bs-dismiss="modal" ref="closeModal" aria-label="Close" v-show="false"></button>
                    </div>
                    <div class="modal-body text-center">
                        <div class="row align-items-center h-100">
                            <div class="col text-center">
                                <h1 class="congratulations">{{message}}</h1>
                                <h2 class="mb-3">{{subtext}}</h2>
                                <p>If you don't receive your airtime or have questions, email us at <a href="mailto:promo@kelloggssuperstars.com">promo@kelloggssuperstars.com</a> or call our customer care number 09070293810 </p>
                                <button class="btn btn-pink px-5 py-3 d-block mb-4 mx-auto" @click="playAgain">Play Again</button>
                                <img src="../assets/img/packshot.png" alt="Logo" class="img-fluid">
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <button type="button" class="btn btn-primary" data-bs-toggle="modal" ref="modal" data-bs-target="#winnerModal" v-show="false">
        </button>
    </div>
</template>

<script>
    export default {
        name: 'Popup',
        props: ['result'],
        data() {
            return {
                message: '',
                subtext: ''
            }    
        },
        methods: {    
            playAgain() {
                this.$refs.closeModal.click();

                this.$router.push('/');
            }
        },
        mounted() {
            if (this.result.label === "Try again" || this.result.id === 0) {
                this.message = 'Almost!';
                this.subtext = `Almost! you didn’t win this time, but you’ve qualified for the grand draw!`;
            } else {
                this.message = 'Congratulations!';
                this.subtext = `You've won airtime worth ${this.result.label}, and are eligble for the grand prize draw.`;
            }
            this.$refs.modal.click();
        }
    };
</script>

<style scoped>
    .congratulations {
        font-size: 50px;
        font-weight: bold;
        margin-bottom: 10px;
    }
</style>
