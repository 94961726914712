<template>
   <div class="iframe-container">
        <iframe
    src="http://muster-meta.us-east-1.elasticbeanstalk.com/public/dashboard/b305570c-0633-4645-86c1-f51ed6fd668f"
    frameborder="0"    
    allowtransparency
    allowfullscreen
></iframe>
      </div>
  </template>

  <script>
  export default {
    name: 'Dash-board',    
  }
</script>

<style scoped>

.iframe-container {
    position: relative;
    width: 100%;
    height: 100vh;
    padding-top: 56.25%; /* 16:9 Aspect Ratio */
}

.iframe-container iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
}

</style>