<template>
  <div>
    <nav class="navbar navbar-expand-lg navbar-light bg-white p-0">
      <div class="container-fluid p-0 margin1">
        <a class="navbar-brand p-0 m-0" href="#">
          <img src="../assets/img/logo-red.png" alt="Logo" class="d-inline-block align-text-top logo-red">
          <RouterLink  to="/"> </RouterLink >
          </a>
          <a class="btn btn-pink"  href="https://www.kelloggssuperstars.com/">
          Visit Our Page
        </a>
      </div>
    </nav>
  </div>
</template>

<script>
</script>

<style>

  @media (min-width: 768px) {
    .margin1 {
      margin-left: 120px !important;
      margin-right: 120px !important;
      margin-top: 24px !important;
      margin-bottom: 24px !important;

    }
  }
  .logo-red {
    width: 138.923px;
    height: 50.995px;
  }

  html {
    scroll-behavior: smooth;
  }

</style>