<template>
  <div class="spin">
    <Spin/>
    
    <div class="d-flex justify-content-center">
      <p class="mb-0">Powered by <img src="../assets/img/kellogs-black.svg" alt="Logo" class="swirl"></p>
    </div>

  </div>
</template>

<script>
import Spin from './spin2.vue'
export default {
  components: {Spin },

}
</script>

<style>
.spin{
  display: flex;
  padding: 80px 120px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 32px;
  align-self: stretch;
  background: var(--Primary, #B51946);

}

.spin-the-wheel-btn{
    display: flex;
    padding: 16px 48px;
    justify-content: center;
    align-items: center;
    gap: 8px;
    border-radius: 16px;
    border: 6px solid #FCEFDE;
    background: var(--Orange, #E1962F);
    box-shadow: 0px 4px 8px -2px rgba(16, 24, 40, 0.10), 0px 2px 4px -2px rgba(16, 24, 40, 0.06);
    transition: transform 0.2s, background-color 0.2s;
  }

  .spin-the-wheel-btn:hover{
    transform: scale(1.05);
    background-color: #c2751e; /* Slightly darker shade of orange */
  }

  .spin-the-wheel-btn:active{
    transform: scale(0.95);
  }

  .spin-the-wheel-btn-text{
    color: #FFF;
    font-family: "Kellogg's Sans";
    font-size: 42px;
    font-style: normal;
    font-weight: 500;
    line-height: 44px;
  }

  @media (max-width: 768px) {
    .spin-the-wheel-btn {
      padding: 12px 36px;
    }

    .spin-the-wheel-btn-text {
      font-size: 34px;
    }

    .spin{
      padding: 8px 40px;

    }
  }

  @media (max-width: 480px) {
    .spin-the-wheel-btn {
      padding: 8px 24px;
    }

    .spin-the-wheel-btn-text {
      font-size: 20px;
    }

    .spin{
      padding: 60px 30px;

    }
  }

</style>