/* eslint-disable */
import { createRouter, createWebHistory } from 'vue-router';
import Home from '../views/home.vue';
import Analytics from '../views/analytics.vue'; 
import SpinHome from '../views/spin-home.vue';

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home
  },
  {
    path: '/dashboard',
    name: 'Dashboard',
    component: Analytics
  },
  {
    path: '/spin',
    name: 'SpinHome',
    component: SpinHome
  }  
  // Add other routes as needed
];

const router = createRouter({
  history: createWebHistory(),
  routes,
  scrollBehavior(to, from, savedPosition) {
    return new Promise((resolve, reject) => {      
      setTimeout(() => {
        resolve({ left: 0, top: 0 })
      }, 200)
    })
  }
});

export default router;
