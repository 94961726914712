<template>
  <div>
    <Navbar />
    <SpinTheWheel />
    <div id="how-to-play-section" class="container-fluid how-to-play">
      <div class="d-flex justify-content-center py-5">
        <div class="how-to-play-box d-flex flex-column align-items-center p-4">
          <div class="d-flex flex-column align-items-center mb-4">
            <p class="how-to-play-text1">How to play</p>
            <img src="../assets/img/vector-37.svg" alt="Logo" class="swirl2">
          </div>
          <div class="d-flex flex-column flex-md-row w-100">
            <div class="col-12 col-md-6 mb-3 d-flex flex-column align-items-center me-1">
              <div class="how-to-play-steps d-flex flex-column">
                <p class="how-to-play-number ms-3 mt-3 mb-3"><span class="how-to-play-number-text">1</span></p>
                <p class="how-to-play-text2 ms-3 mt-1 mb-4">Find your Unique Code in your favourite Kellogg’s Product
                </p>
                <p class="how-to-play-steps-section1 mt-2 mb-0"></p>
              </div>
            </div>

            <div class="col-12 col-md-6 mb-3 d-flex flex-column align-items-center">
              <div class="how-to-play-steps rotate d-flex flex-column">
                <p class="how-to-play-number ms-3 mt-3 mb-3"><span class="how-to-play-number-text text-white">2</span>
                </p>
                <p class="how-to-play-text2 ms-3 mt-1 mb-4">Visit the Kellogg’s Superstars Webpage or Scan the QR Code
                </p>
                <p class="how-to-play-steps-section1 mt-2 mb-0"></p>
              </div>
            </div>
          </div>

          <div class="d-flex flex-column flex-md-row w-100">
            <div class="col-12 col-md-6 mb-3 d-flex flex-column align-items-center">
              <div class="how-to-play-step3 d-flex flex-column">
                <p class="how-to-play-number ms-3 mt-3 mb-3" style="background: var(--Primary, #B51946) !important;">
                  <span class="how-to-play-number-text text-white">3</span></p>
                <p class="how-to-play-text2  ms-3 mt-1 mb-4">Enter your Unique Coupon Code, <span class="spin-win">Spin
                    & Win to win airtime</span></p>
                <p class="how-to-play-steps-section2 mt-2 mb-0"></p>
              </div>
            </div>

            <div class="col-12 col-md-6 mb-3 d-flex flex-column align-items-center">
              <div class="how-to-play-step3 rotate d-flex flex-column">
                <p class="how-to-play-number ms-3 mt-3 mb-3" style="background: var(--Primary, #B51946) !important;">
                  <span class="how-to-play-number-text text-white">4</span></p>
                <p class="how-to-play-text2  ms-3 mt-1 mb-4"> The more you play the higher your chances of winning in
                  the grand draw</p>
                <p class="how-to-play-steps-section2 mt-2 mb-0"></p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <a class="kellogs-logo-large text-center" href="#spin-and-win">
        <img src="../assets/img/hero-footer.png" alt="Logo" class="img-fluid">
      </a>
    </div>
  </div>
</template>

<script>
import Navbar from '@/components/navbar.vue'
import SpinTheWheel from '../components/spin-the-wheel.vue'



export default {
  name: 'App',
  components: {
    Navbar,
    SpinTheWheel
  }
}
</script>

<style scoped>
.how-to-play-spin {
  background: var(--Primary, #ffffff);
}

.how-to-play-text1 {
  color: #000;
}

.how-to-play-box {
  background: var(--Primary, #ffffff);
}

.how-to-play {
  background: var(--Primary, #B51946);
}
</style>