<template>

    <div class="row align-items-center mb-4" id="spin-and-win">

        <div class="modal fade" id="participantForm" tabindex="-1">
            <div class="modal-dialog">
                <div class="modal-content">
                    <div class="modal-header">
                        <h1 class="modal-title fs-5" id="staticBackdropLabel">Please enter your information</h1>
                        <button type="button" class="btn-close" data-bs-dismiss="modal" ref="closeModal"
                            aria-label="Close"></button>
                    </div>
                    <div class="modal-body">
                        <p class="advisory-footnote text-decoration-underline">Tip: Use the same phone number for all
                            your entries to boost your chances of winning!</p>
                        <form @submit.prevent="submitForm" class="row g-3">
                            <div class="col-md-6">
                                <label for="firstname" class="form-label">First name</label>
                                <input type="text" class="form-control" id="firstname" placeholder="John"
                                    v-model="form.firstname" @blur="v$.form.firstname.$touch">
                                <div v-if="v$.form.firstname.$error">
                                    <p class="form__error" v-if="v$.form.firstname.$invalid">This field is required</p>
                                </div>
                            </div>
                            <div class="col-md-6">
                                <label for="lastname" class="form-label">Last name</label>
                                <input type="text" class="form-control" id="lastname" placeholder="Doe"
                                    v-model="form.lastname" @blur="v$.form.lastname.$touch">
                                <div v-if="v$.form.lastname.$error">
                                    <p class="form__error" v-if="v$.form.lastname.$invalid">This field is required</p>
                                </div>
                            </div>
                            <div class="col-12">
                                <label for="phone" class="form-label">Phone number</label>
                                <input type="text" class="form-control" placeholder="0800 000 0000" id="phone" v-model="form.phone" @blur="v$.form.phone.$touch" inputmode="numeric">
                                <div v-if="v$.form.phone.$invalid">
                                    <p class="d-inline form__error" v-if="v$.form.phone.minLength.$invalid || v$.form.phone.maxLength.$invalid">Please enter your full phone number for example 08033332232</p>       
                                    <p class="form__error">This field is required</p>

                                </div>

                            </div>
                            <div class="col-12">
                                <label for="address" class="form-label">Email</label>
                                <input type="text" class="form-control" id="address" v-model="form.email"
                                    placeholder="johndoe@example.com" @blur="v$.form.email.$touch" inputmode="email"/>
                                <div v-if="v$.form.email.$dirty">
                                    <p class="form__error" v-if="v$.form.email.email.$invalid">Please enter a valid
                                        email address </p>
                                    <p class="form__error" v-if="v$.form.email.required.$invalid">This field is required
                                    </p>
                                </div>
                            </div>
                            <div class="col-12 mb-0">
                                <p class="mb-0">Please enter your current location.</p>
                            </div>

                            <div class="col-md-6">
                                <label for="state" class="form-label">State</label>
                                <select id="state" class="form-select" v-model="form.state"
                                    @blur="v$.form.state.$touch">
                                    <option selected>Choose...</option>
                                    <option v-for="state in statesData" :key="state">{{ state }}</option>
                                </select>
                                <div v-if="v$.form.state.$dirty">
                                    <p class="form__error" v-if="v$.form.state.required.$invalid">This field is required
                                    </p>
                                </div>
                            </div>
                            <div class="col-md-6">
                                <label for="city" class="form-label">Local Government Area</label>
                                <select id="city" class="form-select" v-model="form.city"
                                    v-if="form.state && citiesData[form.state]" @blur="v$.form.city.$touch">
                                    <option disabled value="">Choose...</option>
                                    <option v-for="city in citiesData[form.state]" :key="city">{{ city }}</option>
                                </select>
                                <select id="city" class="form-select" v-model="form.city"
                                    v-show="!(form.state && citiesData[form.state])">
                                    <option disabled value="">Choose...</option>
                                    <option v-for="city in citiesData[form.state]" :key="city">{{ city }}</option>
                                </select>
                                <div v-if="v$.form.city.$dirty">
                                    <p class="form__error" v-if="v$.form.city.required.$invalid">This field is required
                                    </p>
                                </div>
                            </div>

                            <div class="col-12">
                                <div class="form-check">
                                    <input class="form-check-input" type="checkbox" id="agree" v-model="form.agree"
                                        value="false">
                                    <label class="form-check-label" for="agree">
                                        You must agree to our <a href="" data-bs-toggle="modal" data-bs-target="#terms">terms and condtions to proceed</a>
                                    </label>
                                    <div v-if="!form.agree">
                                        <p class="form__error" v-if="!isCheckboxChecked">You must agree to continue</p>
                                    </div>
                                </div>
                            </div>
                            <div class="col-12">
                                <button type="button" class="btn btn-pink p-2 w-100" @click="submitForm"
                                    :disabled="!isCheckboxChecked">Submit</button>
                            </div>
                        </form>                       
                    </div>

                </div>
            </div>
        </div>

        <div class="modal fade" id="terms" tabindex="-1">
            <div class="modal-dialog modal-lg">
                <div class="modal-content text-start">
                    <div class="modal-header">
                        <h1 class="modal-title fs-5" id="staticBackdropLabel">Kellogg's Scan and Win Promo - Terms and
                            Conditions</h1>
                        <button type="button" class="btn-close" data-bs-dismiss="modal"
                            aria-label="Close"></button>
                    </div>
                    <div class="modal-body text-start">
                        <ul>
                            <li><strong>Promotion Period:</strong> The Kellogg's Scan and Win Promo ("Promotion") starts
                                on July 15, 2024 and ends on September 20, 2024. Entries received outside this period
                                will not be eligible.</li>
                            <li><strong>Eligibility:</strong>
                                <ul>
                                    <li>The Promotion is open to legal residents of Nigeria.</li>
                                    <li>Participants must be at least 18 years old.</li>
                                    <li>Employees of Kellogg's, its affiliates, subsidiaries, advertising, and promotion
                                        agencies, and immediate family members are not eligible to participate.</li>
                                </ul>
                            </li>
                            <li><strong>How to Enter:</strong>
                                <ul>
                                    <li>Purchase a specially marked Kellogg's cornflakes 45gm pack during the Promotion
                                        Period.</li>
                                    <li>Find the unique alphanumeric code inside the pack.</li>
                                    <li>Scan the QR code on the pack to access the promotion landing page.</li>
                                    <li>Enter your details (name, phone number, email, location) and the alphanumeric
                                        code on the landing page.</li>
                                    <li>Spin the wheel for a chance to win instant prizes like airtime recharge cards.
                                    </li>
                                    <li>Each unique code can only be entered once. Multiple entries are allowed with
                                        additional purchases, increasing your chances of winning the grand draw.</li>
                                </ul>
                            </li>
                            <li><strong>Prizes:</strong>
                                <ul>
                                    <li>Instant prizes: Airtime recharge cards of varying amounts.</li>
                                    <li>Grand prizes: [List of grand prizes, e.g., Cash prizes, Phones, etc.]</li>
                                    <li>The total number of prizes available will be determined by the promoter.</li>
                                </ul>
                            </li>
                            <li><strong>Winner Selection:</strong>
                                <ul>
                                    <li>Instant prizes are awarded based on the spin of the wheel.</li>
                                    <li>Grand prize winners will be selected through a random draw from all eligible
                                        entries at the end of the Promotion Period.</li>
                                </ul>
                            </li>
                            <li><strong>Notification and Claiming of Prizes:</strong>
                                <ul>
                                    <li>Instant prize winners will be notified immediately on the landing page.</li>
                                    <li>Grand prize winners will be notified via phone or email within [3] days of the
                                        draw.</li>
                                    <li>Prizes must be claimed within [7] days of notification. Failure to claim within
                                        this period will result in forfeiture of the prize.</li>
                                </ul>
                            </li>
                            <li><strong>General Conditions:</strong>
                                <ul>
                                    <li>Prizes are non-transferable and no cash alternatives are available.</li>
                                    <li>Kellogg's reserves the right to substitute any prize with another of equivalent
                                        value without giving notice.</li>
                                    <li>By participating, entrants agree to be bound by these terms and conditions and
                                        the decisions of Kellogg's, which are final.</li>
                                </ul>
                            </li>
                            <li><strong>Data Privacy:</strong>
                                <ul>
                                    <li>Personal data collected during the Promotion will be used for the purpose of
                                        administering the Promotion and in accordance with Kellogg's Privacy Policy.
                                    </li>
                                </ul>
                            </li>
                            <li><strong>Limitation of Liability:</strong>
                                <ul>
                                    <li>Kellogg's is not responsible for any technical malfunctions, lost, late,
                                        damaged, misdirected, incomplete, or illegible entries.</li>
                                </ul>
                            </li>
                            <li><strong>Modification and Termination:</strong>
                                <ul>
                                    <li>Kellogg's reserves the right to modify, suspend, or terminate the Promotion if
                                        it cannot be conducted as planned due to any reason beyond its control.</li>
                                </ul>
                            </li>
                            <li><strong>Contact Information:</strong>
                                <ul>
                                    <li>For any inquiries related to the Promotion, please contact
                                        promo@kelloggssuperstars.com</li>
                                </ul>
                            </li>
                        </ul>
                    </div>
                    <div class="modal-footer">
                        <button type="button" class="btn btn-secondary" data-bs-toggle="modal"
                        data-bs-target="#participantForm">Close</button>
                    </div>
                </div>
            </div>
        </div>

        <div class="row pb-5">
            <div class="col p-5">
                <img src="../assets/img/scan-logo.svg" alt="Logo" class="w-75">
            </div>            
        </div>
        <div class="col mx-4 input-container rounded-4 gx-2 p-md-4 p-3 align-items-start position-relative">
            <div class="col-12 text-center mb-3">
                <p class="text-center mt-4">Please enter the unique alphanumeric code e.g. “XXX40R” inside your 45g
                    Kellogg’s Cornflakes Sachet</p>
                <img src="../assets/img/swirl.svg" alt="Logo" class="swirl1">
            </div>
            <div class="col-12 mb-3">
                <div class="row row-cols-6 align-items-center ">
                    <div class="col p-1">
                        <input type="text" maxlength="1" class="coupon-code-input m-0" ref="input1"
                            @input="moveFocus(1)" @keyup.delete="handleKeydown(1)">
                    </div>
                    <div class="col p-1">
                        <input type="text" maxlength="1" class="coupon-code-input m-0" ref="input2"
                            @input="moveFocus(2)" @keyup.delete="handleKeydown(2)">
                    </div>
                    <div class="col p-1">
                        <input type="text" maxlength="1" class="coupon-code-input m-0" ref="input3"
                            @input="moveFocus(3)" @keyup.delete="handleKeydown(3)">
                    </div>
                    <div class="col p-1">
                        <input type="text" maxlength="1" class="coupon-code-input m-0" ref="input4"
                            @input="moveFocus(4)" @keyup.delete="handleKeydown(4)">
                    </div>
                    <div class="col p-1">
                        <input type="text" maxlength="1" class="coupon-code-input m-0" ref="input5"
                            @input="moveFocus(5)" @keyup.delete="handleKeydown(5)">
                    </div>
                    <div class="col p-1">
                        <input type="text" maxlength="1" class="coupon-code-input m-0" ref="input6"
                            @input="moveFocus(6)" @keyup.delete="handleKeydown(6)">
                    </div>
                </div>
            </div>
            <div class="col-12 mb-3">
                <div class="d-grid mb-5">
                    <!-- ADD Loading text or Disable when making api call -->
                    <button class="btn btn-pink start-btn btn" @click="submitCouponCode">Start</button>

                    <button type="button" class="btn btn-primary" data-bs-toggle="modal" ref="modal"
                        data-bs-target="#participantForm" v-show="false">
                    </button>
                </div>

                <div class="d-flex justify-content-center">
                    <p class="mb-0">Powered by <img src="../assets/img/logo-red.png" alt="Logo" class="swirl" style="width: 100px;"></p>
                </div>

            </div>
        </div>
    </div>
</template>


<script>
import config from '../config';
import locationData from '../assets/location.json';
import { required, minLength, numeric, email, maxLength } from '@vuelidate/validators';
import { useVuelidate } from '@vuelidate/core';

export default {
    data() {
        return {
            wonBoxText: '',
            wonBoxTime: '',
            intervalId: null,
            wonTime: new Date(),
            showModal: true,
            form: {
                firstname: '',
                lastname: '',
                phone: '',
                email: '',
                city: '',
                state: '',
                code: '',
                agree: false
            },
            statesData: Object.keys(locationData.States),  // Extracting state names from location.json
            citiesData: locationData.States
        };
    },
    setup() {
        return {
            v$: useVuelidate()
        }
    },
    validations() {
        return {
            form: {
                firstname: { required, $autoDirty: true },
                lastname: { required, $autoDirty: true },
                phone: { required, minLength: minLength(11), maxLength: maxLength(11), numeric, $autoDirty: true },
                email: { required, email, $autoDirty: true },
                state: { required, $autoDirty: true },
                city: { required, $autoDirty: true },
            },
        }
    },
    mounted() {
        this.updateWonData();
        this.intervalId = setInterval(this.updateWonData, 3000);

        const savedForm = localStorage.getItem('form');
        if (savedForm) {
            this.form = JSON.parse(savedForm);
        }
    },
    beforeUnmount() {
        clearInterval(this.intervalId);
    },
    methods: {
        handleKeydown(index) {
            if (index > 1 && index <= 6) {
                const prevInput = this.$refs[`input${index - 1}`];
                prevInput.focus();
            }
        },
        updateWonData() {
            const elapsedMinutes = Math.floor(Math.random() * 6);
            if (elapsedMinutes === 0) {
                this.wonBoxTime = 'just now';
            } else if (elapsedMinutes === 1) {
                this.wonBoxTime = '1 min ago';
            } else {
                this.wonBoxTime = `${elapsedMinutes} mins ago`;
            }

            const names = ['Titilayo Olajide', 'Fasina Boluwatife', 'Ugo Madueke', 'Halima Abdumalik', 'Eka Essien'];
            const amounts = ['NGN 200', 'NGN 500', 'NGN 100'];

            const randomName = names[Math.floor(Math.random() * names.length)];
            const randomAmount = amounts[Math.floor(Math.random() * amounts.length)];

            this.wonBoxText = `${randomName} won ${randomAmount} airtime`;
        },
        moveFocus(index) {
            if (index <= 6) {
                const nextInput = this.$refs[`input${index + 1}`];
                if (nextInput && nextInput.value === '') {
                    nextInput.focus();
                }
            }
        },
        async submitCouponCode() {
            const couponCode = [];
            for (let i = 1; i <= 6; i++) {
                const input = this.$refs[`input${i}`];
                if (input) {
                    couponCode.push(input.value.toUpperCase());
                }
            }
            var validateCouponCodeUrl = config.ValidateCouponCodeUrl.replace('{code}', couponCode.join(''));
            try {
                const response = await fetch(validateCouponCodeUrl);
                if (response.ok) {
                    this.form.code = couponCode.join('');
                    this.$refs.modal.click();
                } else {
                    const errorMessage = await response.json();
                    alert(`Unable to complete your request: ${errorMessage}`);
                    for (let i = 1; i <= 6; i++) {
                        const input = this.$refs[`input${i}`];
                        input.value = "";
                    }
                }
            } catch (error) {
                console.error('Error validating coupon code:', error);
                alert('An error occurred while validating your coupon code.');
            }
        },
        async submitForm() {
            //this.v$.$dirty = true;
            if (!this.v$.$invalid) {
                this.$refs.closeModal.click();
                const parsed = JSON.stringify(this.form);
                localStorage.setItem('form', parsed);
                this.$router.push('/spin');
            } else {
                alert('Please fill all the requred fields');
            }
        }
    },
    computed: {
        isCheckboxChecked() {
            return this.form.agree;
        },
    }
}
</script>

<style>
.scrath-image {
    top: -80px;
    right: -40px;
}

.advisory-footnote {
    font-size: 0.75rem;
    color: #b51946;
    margin-bottom: 1rem;
}

.col-md-6,
.col-12 {
    text-align: start !important;
}

.form__error {
    font-size: 14px;
    margin-bottom: 3px;
    margin-top: 3px;
    color: #ff0000;
}

.input-container {
    border: 3px solid #b51946;
}

.input-container input {
    width: 100%;
    height: 150px;
    text-transform: uppercase;
}


.hero {

    padding-top: 70px;
    padding-bottom: 70px;
}

.won-box {
    height: 40px;
    padding: 8px 12px 8px 24px;
    border-radius: 48px;
    border: 1.5px solid var(--Primary, #B51946);
    background: rgba(255, 255, 255, 0.75);
}

.won-box-text {
    color: var(--Primary, #B51946);
    text-align: center;
    font-family: "Kellogg's Sans";
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    letter-spacing: -0.32px;
    margin: 0;
}



.won-box-time {
    border-radius: 16px;
    background: #F8E8ED;
    color: #C4476B;
    text-align: right;
    font-family: "Kellogg's Sans";
    font-size: 12px;
    font-style: normal;
    font-weight: 500;
    letter-spacing: -0.28px;
}


.spin-text {
    color: var(--Gray-900, #101828);
    text-align: center;
    font-family: "Kellogg's Sans";
    font-style: normal;
    font-size: 88px;
    font-weight: 500;
    line-height: normal;
    letter-spacing: -1.76px;
}

@media screen and (max-width: 576px) {
    .spin-text {
        font-size: 50px;
    }

    .input-container input {
        height: 60px;
    }

    .hero {
        background-size: contain;
    }

    .scrath-image {
        width: 118px;
        top: -60px;
        right: -30px;
    }
}


.spin-text-wheel {
    color: var(--Primary, #B51946);
}

.spin-text-prize {
    color: var(--Orange, #E1962F);
}

.coupon-box {
    padding-bottom: 100px;
    padding-top: 120px;
}

.coupon-box-border {
    border: 5px solid var(--Primary, #B51946);
    border-radius: 18px;
    box-shadow: 0px 117px 32.4px 0px rgba(0, 0, 0, 0.00), 0px 74.7px 29.7px 0px rgba(0, 0, 0, 0.00), 0px 42.3px 25.2px 0px rgba(0, 0, 0, 0.01), 0px 18.9px 18.9px 0px rgba(0, 0, 0, 0.02), 0px 4.5px 9.9px 0px rgba(0, 0, 0, 0.02);
    padding: 28.8px 28.8px 14.4px 28.8px;
    gap: 29px;
}

.start-btn {
    padding: 16px 28px !important;
    color: var(--White, #FFF) !important;
    font-family: "Kellogg's Sans" !important;
    font-size: 22px !important;
    font-style: normal !important;
    font-weight: 500 !important;
    line-height: 28px !important;
    letter-spacing: 1px !important;
}

.coupon-code-input {
    border-radius: 7.2px;
    border: 2.7px solid var(--Gray-500, #667085);
    background: var(--Gray-50, #F9FAFB);
    text-align: center;
    font-size: 24px;
    font-family: "Kellogg's Sans";
}

.swirl1 {
    width: 169.99px;
    height: 33.638px;
    margin: auto;
}
</style>
