<template>
  <div id="how-to-play-section" class="container-fluid">
    <div class="d-flex justify-content-center py-5">
      <div class="how-to-play-box d-flex flex-column align-items-center p-4">
        <div class="d-flex flex-column align-items-center mb-4">
          <p class="how-to-play-text1">How to play</p>
          <img src="../assets/img/vector-37.svg" alt="Logo" class="swirl2">
        </div>
        <div class="d-flex flex-column flex-md-row w-100 mb-3">
          <div class="col-12 col-md-6 mb-3 d-flex flex-column align-items-center me-1">
            <div class="how-to-play-steps d-flex flex-column">
              <p class="how-to-play-number ms-3 mt-3 mb-3"><span class="how-to-play-number-text">1</span></p>
              <p class="how-to-play-text2 ms-3 mt-1 mb-4">Find the Unique Code in your 45g Kellogg’s Cornflakes Sachet</p>
              <p class="how-to-play-steps-section1 mt-2 mb-0"></p>
            </div>
          </div>

          <div class="col-12 col-md-6 mb-3 d-flex flex-column align-items-center">
            <div class="how-to-play-steps rotate d-flex flex-column">
              <p class="how-to-play-number ms-3 mt-3 mb-3"><span class="how-to-play-number-text text-white">2</span></p>
              <p class="how-to-play-text2 ms-3 mt-1 mb-4">Visit the Kellogg’s Superstars Webpage or Scan the QR Code</p>
              <p class="how-to-play-steps-section1 mt-2 mb-0"></p>
            </div>
          </div>
        </div>

        <div class="d-flex flex-column flex-md-row w-100">
          <div class="col-12 col-md-6 mb-3 d-flex flex-column align-items-center">
          <div class="how-to-play-step3 d-flex flex-column">
            <p class="how-to-play-number ms-3 mt-3 mb-3" style="background: var(--Primary, #B51946) !important;"><span class="how-to-play-number-text text-white">3</span></p>
            <p class="how-to-play-text2  ms-3 mt-1 mb-4">Enter your Unique Coupon Code, <span class="spin-win">Spin & Win to win airtime</span></p>

            <p class="how-to-play-steps-section2 mt-2 mb-0"></p>
          </div>
        </div>

        <div class="col-12 col-md-6 mb-3 d-flex flex-column align-items-center">
          <div class="how-to-play-step3 rotate d-flex flex-column">
            <p class="how-to-play-number ms-3 mt-3 mb-3" style="background: var(--Primary, #B51946) !important;"><span class="how-to-play-number-text text-white">4</span></p>
            <p class="how-to-play-text2  ms-3 mt-1 mb-4"> The more you play the higher your chances of winning in the grand draw</p>
            <img src="../assets/img/giftssss.png" alt="Logo" class="w-100">

            <p class="how-to-play-steps-section2 mt-2 mb-0"></p>
          </div>
        </div>
        </div>

      
      </div>
    </div>
    <a class="kellogs-logo-large text-center" href="#spin-and-win">
      <img src="../assets/img/hero-footer.png" alt="Logo" class="img-fluid" >
    </a>
  </div>
</template>

<script>
export default {

}
</script>

<style>
.how-to-play-box {
  width: 100%;
  max-width: 1280px;
  display: flex;
  align-items: center;
  border-radius: 48px;
  background: var(--Primary, #B51946);
}

.how-to-play-text1 {
  color: var(--White, #FFF);
  font-family: "Kellogg's Sans";
  font-size: 2.5rem;
  font-weight: 500;
  text-align: center;
}

.how-to-play-text2 {
  color: var(--Gray-900, #101828);
  font-family: "Kellogg's Sans";
  font-size: 1.25rem;
  font-weight: 500;
  width: 60%;
  text-align: left;
}

.how-to-play-text3 {
  color: var(--Gray-900, #101828);
  font-family: "Kellogg's Sans";
  font-size: 1.5rem;
  font-weight: 500;
  width: 60%;
  text-align: left;
}

.spin-win {
  color: var(--Primary, #B51946);
  font-family: "Kellogg's Sans";
  font-size: 1.5rem;
  font-weight: 500;
}

.swirl2 {
  width: 135px;
  height: 43px;
}

.how-to-play-steps,
.how-to-play-step3 {
  border-radius: 24px;
  background: var(--White, #FFF);
  box-shadow: 0px 4px 8px -2px rgba(16, 24, 40, 0.10), 0px 2px 4px -2px rgba(16, 24, 40, 0.06);
  width: 100%;
  position: relative;
}

.how-to-play-steps {
  border: 6px solid #F5D77C;
}

.how-to-play-step3 {
  border: 6px solid #CD6583;
}

.how-to-play-number {
  display: flex;
  padding: 4px 16px;
  flex-direction: column;
  align-items: flex-start;
  border-radius: 300px;
  background: var(--Yellow, #F0C33B);
  align-content: flex-start;
  width: fit-content;
}

.how-to-play-number-text {
  color: var(--Gray-900, #101828);
  font-family: Hellix;
  font-size: 1.5rem;
  font-weight: 600;
  text-align: center;
}

.how-to-play-steps-section1,
.how-to-play-steps-section2 {
  width: 100%;
  height: 120px;
  border-bottom-left-radius: 19px;
  border-bottom-right-radius: 19px;
}

.how-to-play-steps-section1 {
  background: var(--Yellow, #F0C33B);
}

.how-to-play-steps-section2 {
  background: var(--Primary, #B51946);
}

.rotate {
  transform: rotate(2deg);
}

@media screen and (max-width: 576px) {
  .rotate {
  transform: rotate(0deg);
}
}

.step-image {
  position: absolute;
  width: 100%;
  height: auto;
  top: -50px;
  left: 50%;
  transform: translateX(-50%);
  object-fit: cover;
  border-radius: inherit;
}

.kellogs-logo-large {
  background: var(--White, #FFF);
}
</style>
