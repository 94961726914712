<template>
  <div id="app" class="container">
    <router-view></router-view>
  </div>
</template>

<script>




export default {
  name: 'App',
  components: {
  }
}
</script>


<style>

@font-face {
    font-family: "Kellogg's Sans";
    src: url('assets/font/KELLOGGSSANS-LIGHT.OTF') format("opentype");
    font-weight: normal;
    font-style: normal;
}

@font-face {
  font-family: "Kellogg's Sans";
  src: url("assets/font/KELLOGGSSANS-REGULAR.OTF") format("opentype");
  font-weight: 300;
  font-style: normal;

}

@font-face {
  font-family: "Kellogg's Sans";
  src: url("assets/font/KELLOGGSSANS-ITALIC.OTF") format("opentype");
  font-weight: normal;
  font-style: italic;
}

@font-face {
  font-family: "Kellogg's Sans";
  src: url("assets/font/KELLOGGSSANS-BOLD.OTF") format("opentype");
  font-weight: bold;
  font-style: normal;
}

@font-face {
  font-family: "Kellogg's Sans";
  src: url("assets/font/KELLOGGSSANS-MEDIUM.OTF") format("opentype");
  font-weight:  medium;
  font-style: normal;
}
#app {
  font-family: "Kellogg's Sans";
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}

 .btn-pink {
    --btn-color: #fff;
    --btn-bg: #B51946;
    --btn-border-color: #B51946;
    --btn-hover-color: #fff;
    --btn-hover-bg: #e57ea0;
    --btn-hover-border-color: #c93c6b;
    --btn-focus-shadow-rgb: 181, 25, 70;
    --btn-active-color: #fff;
    --btn-active-bg: #b33355;
    --btn-active-border-color: #7a1230;
    --btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
    --btn-disabled-color: #fff;
    --btn-disabled-bg: #B51946;
    --btn-disabled-border-color: #B51946;
  }

  .btn-pink {
    color: var(--btn-color) !important;
    background-color: var(--btn-bg) !important;
    border-color: var(--btn-border-color) !important;
  }

  .btn-pink:hover {
    color: var(--btn-hover-color);
    background-color: var(--btn-hover-bg);
    border-color: var(--btn-hover-border-color);
  }

  .btn-pink:focus,
  .btn-pink.focus {
    color: var(--btn-hover-color);
    background-color: var(--btn-hover-bg);
    border-color: var(--btn-hover-border-color);
    box-shadow: 0 0 0 0.2rem rgba(var(--btn-focus-shadow-rgb), 0.5);
  }

  .btn-pink:active,
  .btn-pink.active,
  .show > .btn-pink.dropdown-toggle {
    color: var(--btn-active-color);
    background-color: var(--btn-active-bg);
    border-color: var(--btn-active-border-color);
    box-shadow: var(--btn-active-shadow);
  }

  .btn-pink:disabled,
  .btn-pink.disabled {
    color: var(--btn-disabled-color);
    background-color: var(--btn-disabled-bg);
    border-color: var(--btn-disabled-border-color);
  }
</style>
